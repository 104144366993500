@import './base.scss';

footer {
  background-color: lighten(black, 5%);
  padding: 4rem 2rem 2rem 4rem;
  @include tablet {
    padding: 5rem;
  }
  color: gray;
  a {
    color: lighten(gray, 15%);
    &:hover {
      color: lighten(gray, 25%);
    }
  }
}

.re-contact {
  .modal-card {
    max-width: 90%;
  }

  .modal-card-body p {
    margin-bottom: 2rem;
  }

  .field.is-grouped {
    justify-content: flex-end;
  }
}
