@import './base.scss';
@import './carousel.scss';

.re-hero {
  margin: 0 auto;
  min-height: 80vh;
  background: linear-gradient(to bottom, white, #fafafa);
  margin-bottom: 0 !important;

  .title.is-1 {
    font-size: 2rem;
  }

  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 2rem;
  padding-bottom: 3rem;


  @include tablet {
    padding-left: 10%;
    padding-right:10%;
    padding-top: 10rem;
    padding-bottom: 3rem;
    .title.is-1 {
      font-size: 3rem;
    }

    .column {
      padding: 1rem 5rem 1rem 5rem;
    }
  }
  .carousel {
    padding-bottom: 3rem;
    .dot {
      background-color: lightgrey;
    }
    .slide {
      img {
        width: auto;
      }
      background-color: transparent;
    }
    .control-next.control-arrow:before {
      border-left: 8px solid $primary;
    }
    .control-prev.control-arrow:before {
      border-right: 8px solid $primary;
    }
  }
  .re-screenshot {
    border: 1px solid #b8b6bb !important;
    border-radius: 10px;
    overflow: hidden;
  }

  .buttons {
    justify-content: space-around;
    margin-top: 2rem;
    margin-bottom: 2rem;
    .button {
      min-width: 40%;
      padding: 1.4rem;
      img {
        width: 25px;
        margin-right: 1rem;
      }
    }
  }

  p {
    font-size: 1.1rem;
  }
}

.re-arrow-container-1 {
  background-color: $darkred;
  svg {
    width: 100%;
    polygon {
      fill: #fafafa;
    }
  }
}

.re-features {
  min-height: 50vh;
  padding-bottom: 5rem;
  background-color: $darkred;
  color: white;
  .title {
    color: white;
    margin-bottom: 3rem !important;
  }

  p {
    b {
      text-transform: uppercase;
      font-size: 1.4rem;
      font-weight: 100;
    }
    margin-bottom: 2rem;
    font-size: 1.1rem;
    font-weight: 400;
  }

  code {
    background-color: lighten($darkred, 5%);
    color: white;
    border-radius: 5px;
  }

  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 2rem;
  @include tablet {
    padding-left: 30%;
    padding-right: 30%;
    padding-top: 8rem;
  }
}

.re-arrow-container-2 {
  background-color: white;
  svg {
    width: 100%;
    polygon {
      fill: $darkred;
    }
  }
}

.re-easy {
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 2rem;
  @include tablet {
    padding-left: 30%;
    padding-right: 30%;
    padding-top: 8rem;
  }

  padding-bottom: 8rem;
  font-size: 1.1rem;

  .re-commands {
    margin-top: 2rem;
    border: 1px solid black;
    border-radius: 10px;
    border-collapse: collapse;
    background-color: whitesmoke;
    overflow: hidden;
    width: 100%;

    td {
      padding: 1.5rem;
      code {
        font-family: monospace;
        font-size: 1.2rem;
        background-color: inherit;
        color: black;
      }
    }
  }
}

.re-arrow-container-3 {
  background-color: black;
  svg {
    width: 100%;
    polygon {
      fill: white;
    }
  }
}

.re-focus {
  padding-bottom: 12rem;
  background-color: black;
  color: white;
  .title {
    color: white;
  }
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 2rem;

  @include tablet {
    padding-left: 30%;
    padding-right: 30%;
    padding-top: 8rem;
  }

  li {
    font-size: 1.4rem;
    margin-bottom: 2rem;
  }

  blockquote {
    margin-top: 5rem;
    background-color: #161619;
    padding: 2.5rem;
    font-size: 1.4rem;
    border-left: 5px solid #323238;
  }
}
