@import './base.scss';

.navbar-brand {
  .navbar-item {
    img {
      max-height: 2.75rem;
      margin-right: 0.6rem;
    }
  }
  .title {
    font-family: 'Lato', sans-serif;
    font-weight: 900;
    color: black;
    font-size: 2rem;
  }
}

.navbar-item.has-dropdown {
  align-items: center !important;
}

.re-pricing {
  .modal-card-body {
    padding: 2rem;
    background-color: #fafafa;
    .column {
      padding: 2rem;
      margin: 1rem;
      background-color: white;
      border: 1px solid lightgrey;
      border-radius: 5px;
      .subtitle {
        color: gray;
      }

      em {
        font-size: 1.2rem;
      }
    }
  }
}
