$darkred: #4e0917;
$primary: #ed2f54;
//$primary: $darkred;
$link: $primary;
$family-sans-serif: 'Lato', sans-serif;

@import '~bulma';
@import "./animate.scss";
@import './spinner.scss';

$lightgray: #ececeb;
$blue: #10316b;
$black: #000000;

a:hover {
  color: darken($primary, 10%);
}

.modal-card-body {
  border-radius: 0px 0px 5px 5px;
}
